 
.noselect1 {
  
    -webkit-touch-callout: none; 
      -webkit-user-select: none; 
       -khtml-user-select: none; 
         -moz-user-select: none; 
          -ms-user-select: none; 
              user-select: none; 
                                    
  }
  
.total {
    background-color: black;
    height: 2px;
    display: block;
    text-align: end;
    align-items: end;
    flex: auto;
    justify-content: end;
    width: 100% !important;
}

table {
     border-spacing:0px !important; 
    
}

.numBlock {
    width: fit-content !important;
    height: fit-content !important;
    /* padding:20px; */
    border-radius: 1px;
    border: 1px groove wheat;
  
}  

.grid-full {
    /* background-color: rgba(100, 100, 100, 0.9); */
    border-radius: 5px;
}

.grid-empty {
    border-radius: 5px;
}

.HBlock  {
    display: flex;
    text-align: center;
    align-items: center;
    font-size: calc(0px + 0vmin);
    width: 40px !important;
    height: 35px !important;
    font-family: fantasy;
    padding:1px;
    border-radius: 5px;
}

.HBlock:hover {
    position: relative;
    padding: 0px;
    border: 1px outset gray;
    transform: scale(1.1);
    z-index: 100;
    display: flex;
}

.Block {
    /* margin-bottom: 2px; */
    /* margin-top: 2px; */
    display: flex;
    text-align: center;
    align-items: center;
    font-size: calc(8px + 1vmin);
    width: 40px !important;
    height: 35px !important;
    font-family: fantasy;
    padding:1px;
    border-radius: 5px;
    transition: all .2s ease-in-out;
    /* border: 3px groove wheat; */
}

.BlockD {
    display: flex;
    text-align: center;
    align-items: center;
    font-size: calc(8px + 1vmin);
    width: 40px !important;
    height: 35px !important;
    font-family: fantasy;
    padding:1px;
    border-radius: 5px;

    -webkit-touch-callout: none; 
    -webkit-user-select: none; 
     -khtml-user-select: none; 
       -moz-user-select: none; 
        -ms-user-select: none; 
            user-select: none; 

}

.AN {
    position: absolute;
    text-align: top;
    float: left;
    padding: 0;
    margin-left: 2px;
    font-size: 50%;
    align-self: flex-start;
}

.Symbol {
    color: black;
    position: absolute;
    align-self: center;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 4px;
    margin-left: 8px;
    
}

.Block .Name {
    visibility: hidden;
    color: black;
    position: relative;
    font-size: 5px;
    align-self: flex-end;
    margin-left: 2px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}



.Block:hover {
    position: relative;
    padding: 0px;
    border: 1px outset white;
    transform: scale(1.5);
    z-index: 100;
    display: flex;

}

.Block:hover .Name{
    visibility: visible;
}



.catLegend{
    text-align: left;
    justify-content: flex-start;

}

.catLegend:hover{
    position: relative;
    padding: 0px;
    margin-left: 15px;
    font-weight: bold;
}


.Blank{
    text-align: center;
    align-items: center;
    font-size: calc(18px + 2vmin);
    width: 50px !important;
    height: 50px !important;
    font-weight: bold;
    font-family: fantasy;
}

span[class^='Color'] {
    /* margin-bottom: 2px; */
    /* margin-top: 2px; */
    display: flex;
    text-align: center;
    align-items: center;
    font-size: calc(8px + 1vmin);
    width: 50px !important;
    height: 10px !important;
    font-family: fantasy;
    padding:1px;
    border-radius: 5px;
    transition: all .2s ease-in-out;
    /* border: 3px groove wheat; */
}

.Color100{
    background-color: white !important;
    border: 1px solid black;
}



.Color0{
    background-color: lightgray !important;
}



.Color1 {
background-image: url("../images/planet_01.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover;


}

.Color2 {
background-image: url("../images/planet_02.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover;

}

.Color3 {
background-image: url("../images/planet_03.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover;
font-family: fantasy;
}

.Color4 {
background-image: url("../images/planet_04.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover;
}

.Color5 {
background-image: url("../images/planet_05.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover;
}
.Color6 {
background-image: url("../images/planet_06.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover;
}

.Color7 {
background-image: url("../images/planet_07.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover;
}

.Color8 {
background-image: url("../images/planet_08.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover;
}
.Color9 {
background-image: url("../images/planet_09.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover;
}

.Color10 {
    background-image: url("../images/planet_10.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    }

.Color11 {
    background-image: url("../images/planet_11.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    }

.Color12 {
    background-image: url("../images/planet_12.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
    
/* .ColorX {
    background-image: url("../images/planet_00.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
} */

.dlegend{
   border: double !important;
   width: fit-content;
}

.animation{
    display: none;
}

.summary {
    font-size: 80%;
}

/* Responsive layout - makes the three columns stack on top of each other instead of next to each other on smaller screens (600px wide or less) */
/* @media screen and (max-width: 500px) {
    .w3-col {
      width: 100%;
    }
} */


/* @media screen and (max-width: 1025px) {
   
    .mcontainer {
      transform: scale(0.9) translateX(5%) ;
    } 
    .dlegend{
        transform: translateX(75%);
    }   
 
} */

/* @media screen and (max-width: 1000px) {
    .mcontainer {
      transform: scale(0.9) translateX(5%) translateY(-5%);
    }    
} */


@media screen and (max-width: 900px) {
    .mcontainer {
      transform: scale(0.9) translateX(-5%) translateY(-5%);
    }   
    .BBlock {
        transform:  scale(0.9) translateX(-5%) translateY(-1%);
    } 
}

@media screen and (max-width: 800px) {
    .mcontainer {
      transform: scale(0.8) translateX(-1%) translateY(-10%);
    }  
    .BBlock {
        transform:scale(0.8) translateX(-12%) translateY(-10%);
    }  
}

@media screen and (max-width: 700px) {
    .mcontainer {
      transform: scale(0.7) translateX(-7%) translateY(-25%);
    }  
    .BBlock {
        transform: scale(0.7) translateX(-7%) translateY(-25%);
    }  
}

@media screen and (max-width: 600px) {
    .mcontainer {
      transform: scale(0.6) translateX(-20%) translateY(-30%);
    }  
    .BBlock {
        transform: scale(0.6) translateX(-33%) translateY(-35%);
    }  
}

@media screen and (max-width: 500px) {
    .mcontainer {
      transform: scale(0.45) translateX(-40%) translateY(-55%);
    }    
    .BBlock {
        transform: scale(0.45) translateX(-61%) translateY(-61%);
    }

  
}

@media screen and (max-width: 400px) {
    .mcontainer {
      transform: scale(0.4) translateX(-55%) translateY(-75%);
    }    
    .BBlock {
        transform: scale(0.4)  translateX(-75%) translateY(-83%);
    }

}

@media screen and (max-width: 300px) {
    .mcontainer {
      transform: scale(0.3) translateX(-85%) translateY(-115%);
    }
    .BBlock {
        transform: scale(0.30)  translateX(-116%) translateY(-125%);
    }
}


.BBlock:hover {
    position: relative;
    padding: 0px;
    border: 1px groove blue;
    transform: scale(0.8) translateX(-10%);
    transform-origin: left;
    z-index: 100;
    display: flex;
}

@media screen and (max-width: 900px) {
    .BBlock:hover {
        position: relative;
        padding: 0px;
        border: 1px groove blue;
        transform: scale(1.5) translateX(-15%);
        transform-origin: left;
        z-index: 100;
        display: flex;
    }
}

@media screen and  (hover: hover) {
    .BBlock:hover {
        position: relative;
        padding: 0px;
        border: 1px groove blue;
        transform: scale(1.5);
        transform-origin: left;
        z-index: 100;
        display: flex;
    }
}
