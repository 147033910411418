.mcontainer {
    margin-top: 5px;
}

.legend1 {
    align-self: flex-end; 
}

.elemDesc{
    position:absolute;
    /* margin-left:87px;
    margin-top:-375px; */
}

.BBlock {
    display: flex;
    text-align: left;
    align-items: left;
    background-color: cadetblue !important;
    width: 415px !important;
    height: 100px !important;
    font-weight: bold;
    padding:1px !important;
    border-radius: 5px;
    transition: all .2s ease-in-out;
}



.summary:hover{
    font-size: larger;
    border: 2px groove gold;
    border-radius: 3px;
    background-color: blanchedalmond;
    display: flex;
}


.BAN {
    text-align: top;
    float: left;
    padding: 0;
    align-self: flex-start;
}

.BSymbol {
    color: black;
    align-self: center;
}

.BName {
    color: black;
    margin-left: 20px;
    align-self:flex-start;
    justify-content: flex-end;
    font-size: 60%;
    float: right;
}

.mprop{
    font-size: 50%;
}

.descRow{
   width:"100%" !important;
}

.card-horizontal {
    display: flex;
    flex: 1 1 auto;
    color: black;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 
            'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 
                        'Open Sans', 'Helvetica Neue', sans-serif;
  
}

.no-pad {
    padding: 2px 5px 2px 5px !important;
}
 
/* .row-striped:nth-of-type(odd){
    background-color: cyan;
}

.row-striped:nth-of-type(even){
    background-color: lightcyan;
}  */

.small-ext {
    font-size: "8px" !important;
}

.dImage{
    width:auto;
    height:95px;
    padding:2px;
}

.label{
    font-size: 70%;
}

.value{
    font-size: 85%;
    color: brown;
}

.mhr {
    width:100%;
    background-color: brown;
    height: 1px;
}