body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Responsive layout - makes the three columns stack on top of each other instead of next to each other on smaller screens (600px wide or less) */
@media screen and (max-width: 800px) {
    .Block {
      /* margin-bottom: 2px; */
      /* margin-top: 2px; */
      display: flex;
      text-align: center;
      align-items: center;
      font-size: calc(8px + 1vmin);
      width: 15px !important;
      height: 35px !important;
      font-family: fantasy;
      padding:1px;
      border-radius: 5px;
      transition: all .2s ease-in-out;
      /* border: 3px groove wheat; */
  }
}